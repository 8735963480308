import axios from "axios";
import AppContext from "../Context/Context";
import NavigateURL from "../NavigateURL";

const axiosAuth = axios.create();

//we intercept every requests
axiosAuth.interceptors.request.use(
  async function (config) {
    config.withCredentials = true;
    //anything you want to attach to the requests such as token
    const Local_Token = process.env.REACT_APP_Local_Token;
    if (Local_Token && Local_Token !== "") {
      config.headers.Authorization = "Bearer " + Local_Token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//we intercept every response
axiosAuth.interceptors.response.use(
  async function (response) {
    return response;
  },
  (error) => {
    //check for authentication or anything like that
    if (error.response.status === 401) {
      <NavigateURL/>
    }
    return Promise.reject(error);
  }
);

export default axiosAuth;
