import { Routes, Route } from "react-router-dom";
import Header from "./components/App/Header";
import RequireAuth from "./components/AuthGuard/RequireAuth";
import { GlobalStateProvider } from "./components/Context/GlobalStateContext";
import RequireAuthJLR from "./components/AuthGuard/RequireAuthJLR";
import MBT from "./components/Pages/MBT";
import MBTAdmin from "./components/Pages/MBTAdmin";
import RequireAuthAdmin from "./components/AuthGuard/RequireAuthAdmin";
import TestPage from "./components/Pages/TestPage";


function App() {
  return (
    <main>
      <GlobalStateProvider>
        <div className="App--main">
          <Header />
          <div className="Body--Main">
            <Routes>
              <Route
                path=""
                element={
                  <RequireAuth>
                    <MBT />
                  </RequireAuth>
                }
              />
              <Route
                path="mbt"
                element={
                  <RequireAuth>
                    <MBT />
                  </RequireAuth>
                }
              />
              <Route
                path="mbtadmin"
                element={
                  <RequireAuthAdmin>
                    <MBTAdmin />
                  </RequireAuthAdmin>
                }
              />
            <Route
                path="*"
                element={
                  <RequireAuth>
                  <MBT />
                </RequireAuth>
                }
              />
            </Routes>
          </div>
        </div>
      </GlobalStateProvider>
    </main>
  );
}

export default App;
