import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../Context/GlobalStateContext";
import AppContext from "../Context/Context";
import axiosAuth from "../Axios/Interceptors";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
function MBTAdmin() {
  const globalState = useGlobalState();
  const [loadingState, setLoadingState] = useState(false);
  const [businessPlanAdminControlsData, setBusinessPlanAdminControlsData] =
    useState<any>([]);

  useEffect(() => {
    getBusinessPlanAdminControlsData();
  }, []);

  function getBusinessPlanAdminControlsData() {
    let object = {} as any;
    setLoadingState(true);
    axiosAuth
      .post(
        AppContext.AppBaseURL +
          "api/PFIBusinessPlan/GetBusinessPlanAdminControlsData",
        object
      )
      .then((response) => {
        if (response.data.Status === 1) {
          var l_response = response.data.Data;
          console.log(l_response);
          setBusinessPlanAdminControlsData(l_response);
        }
        setLoadingState(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  return (
    <>
        {loadingState && <LoadingAnimation />}
      <div className="admin-main">
        {businessPlanAdminControlsData[1] &&
          businessPlanAdminControlsData[1].map((Item: any, index: number) => (
            <React.Fragment key={index}>
              <div className="admin-inn">
                <div className="flex-title links-title">
                  <span className=" a-accord">{Item.Category}</span>
                </div>
                <div className="links-flex">
                  {businessPlanAdminControlsData[0] &&
                    businessPlanAdminControlsData[0].map(
                      (CategoryItem: any, Categoryindex: number) => (
                        <React.Fragment key={Categoryindex}>
                          {CategoryItem.PTAdminControlCategoryID ==
                            Item.PTAdminControlCategoryID && (
                            <a
                              href={CategoryItem.PageLink}
                              className="links-det"
                            >
                              <img
                                src={CategoryItem.IconImageURL}
                                width="50px"
                                height="50px"
                                alt={CategoryItem.Name}
                              />
                              <span>{CategoryItem.Name}</span>
                            </a>
                          )}
                        </React.Fragment>
                      )
                    )}
                </div>
              </div>
            </React.Fragment>
          ))}
      </div>
    </>
  );
}

export default MBTAdmin;
