import React, { useEffect, useState } from "react";
// import ClipLoader from "react-spinners/ClipLoader";
export default function LoadingAnimation() {
  return (
    <div className="loader-overlay">
      {/* <ClipLoader
        color={"#d31c5b"}
        loading={true}
        //   cssOverride={override}
        size={50}
        aria-label="Loading Spinner"
      /> */}
      <img src="images/indicator-big.gif"/>
    </div>
  );
}
