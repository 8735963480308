import { Link, NavLink } from "react-router-dom";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "../Context/Context";
import DragDrop from "../DragDrop/DragDrop";
import axiosAuth from "../Axios/Interceptors";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { useGlobalState } from "../Context/GlobalStateContext";
import Notification from "./Notification";
export default function Header() {
  const globalState = useGlobalState();
  const [contactUsReason, setcontactUsReason] = useState([]);
  const [uploadFileList, setUploadFileList] = useState([]);
  const [triggerChild, setTriggerChild] = useState(false);
  const [isOkToGo, setIsOkToGo] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [ShowMenu, SetShowMenu] = useState(false);
  const ShowHideMenu = () => {
    SetShowMenu(true);
  };

  const [ShowContact, SetShowContact] = useState(false);
  const ShowHideContact = () => {
    SetShowContact(!ShowContact);
  };

  const [contactObject, setcontactObject] = useState({
    Name: globalState.LogInUserName,
    Email: "",
    Subject: "",
    Message: "",
    ContactReason: 0,
  });

  const [dragDropFeatures, setDragDropFeatures] = useState({
    ObjectID: 0,
    ObjectType: 1,
    MaxAllowedFiles: 4,
    UploadType: 1,
    FolderName: "Contact",
    PTUploadedFiles: [],
  });

  //Logout Menu
  const [showLogout, setshowLogout] = useState(false);
  const ShowHideLogoutMenu = () => {
    setshowLogout(!showLogout);
  };

  const [refresh, setRefresh] = useState(false);

  const [ShowFolder, SetShowFolder] = useState(false);
  const ShowHideFolder = () => {
    SetShowFolder(!ShowFolder);
  };

  function handleDragDropCallback(ChildData: any) {
    setUploadFileList(ChildData);
  }

  useEffect(() => {
    // getContactUsPageData();
  }, []);

  function getContactUsPageData() {
    let object = {} as any;
    object.appid = AppContext.App;
    axiosAuth
      .post(AppContext.CommAPI + "api/PTSupport/GetSupportTypeList", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data[0];
          setcontactUsReason(l_response);
        } else {
          AppContext.ShowNotification("error", response.data.Message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }

  useEffect(() => {
    //checkValidation(false);
  }, [contactObject]);

  function checkValidation(takeAction: boolean) {
    if (
      contactObject.Name === "" ||
      contactObject.Email === "" ||
      contactObject.Message === "" ||
      contactObject.ContactReason === 0
    ) {
      if (takeAction) {
        globalState.setNotificationObject({
          ShowPopup: true,
          Heading: "Alert",
          Message: "You need to select from all the information to continue.",
        });
      }
      {
        setIsOkToGo(false);
      }
    } else {
      setIsOkToGo(true);
      if (takeAction) {
        SaveFiles();
      }
    }
  }

  function clearObject() {
    setcontactObject({
      Name: globalState.LogInUserName,
      Email: "",
      Subject: "",
      Message: "",
      ContactReason: 0,
    });
  }

  function SaveFiles() {
    setLoadingState(true);
    if (uploadFileList && uploadFileList.length > 0) {
      let object = {} as any;
      object.typeid = 1;
      object.attachmenttype = dragDropFeatures.ObjectType;
      object.objectid = dragDropFeatures.ObjectID;
      object.applicationid = 1;
      object.filesdata = uploadFileList;
      axiosAuth
        .post(
          AppContext.FileManegmentAPI + "api/PTAttachment/SaveFilesData",
          object
        )
        .then((response) => {
          if (response.data.Status === 1) {
            setTriggerChild(!triggerChild);
            let l_response = response.data.Data;
            saveConactUsData(l_response);
          } else {
            AppContext.ShowNotification("error", response.data.Message);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      saveConactUsData([]);
    }
  }

  function saveConactUsData(p_S3UploadFileList: any) {
    setLoadingState(true);
    let object = {} as any;
    object.appid = AppContext.App;
    object.typeid = 1;
    object.ptsupporttypeid = contactObject.ContactReason;
    object.subject = "";
    object.firstname = contactObject.Name;
    object.email = contactObject.Email;
    object.contact = "";
    object.message = contactObject.Message;
    object.filesdata = p_S3UploadFileList;
    axiosAuth
      .post(AppContext.CommAPI + "api/PTSupport/SupportSubmitData", object)
      .then((response) => {
        if (response.data.Status === 1) {
          let l_response = response.data.Data;
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: "Your query has been submitted.",
          });
        } else {
          globalState.setNotificationObject({
            ShowPopup: true,
            Heading: "Alert",
            Message: response.data.Message,
          });
        }
        clearObject();
        SetShowContact(false);
        setLoadingState(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoadingState(false);
      });
  }

  useEffect(() => {
    setRefresh(!refresh);
  }, [AppContext.BPID]);

  useEffect(() => {
    setcontactObject((prevState: any) => ({
      ...prevState,
      Name: globalState.LogInUserName,
    }));
    setRefresh(!refresh);
  }, [globalState.LogInUserName]);

  const location = useLocation();
  useEffect(() => {
    // console.log(location);
  }, [location.pathname]);

  function NotificationCallBack() {
    globalState.setNotificationObject({
      ShowPopup: false,
      Heading: "",
      Message: "",
    });
  }

  const folderRef = useRef<any>(null);
  const userRef = useRef<any>(null);
  const menuRef = useRef<any>(null);

  useEffect(() => {
    // Function to handle outside clicks
    const handleClickOutside = (event: any) => {
      // Check if the click target is outside the component's DOM node
      if (folderRef.current && !folderRef.current.contains(event.target)) {
        SetShowFolder(false);
      }
      if (userRef.current && !userRef.current.contains(event.target)) {
        setshowLogout(false);
      }
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        SetShowMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Notification
        NotificationObject={globalState.NotificationObject}
        NotificationCallBack={NotificationCallBack}
      />
      <header className="header">
        {loadingState && <LoadingAnimation />}
        <div className="header--menu__left">
          <div className="header--left__logo">
            <img src="/images/logo-black.png" width="142px" height="30px" />
          </div>
          <div>
            {globalState.LogInUserRole == "Admin" && (
              <>
                {(location.pathname == "/mbt" ||location.pathname == "/") && (
                  <NavLink
                    to={"mbtadmin"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    <span className="sub-span">Admin</span>
                  </NavLink>
                )}
                {location.pathname == "/mbtadmin" && (
                  <NavLink
                    to={"mbt"}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    <span className="sub-span">MBT</span>
                  </NavLink>
                )}
              </>
            )}
          </div>
        </div>
        <div className="header--menu__right">
          <div className="folder-inn" ref={folderRef}>
          {(location.pathname != "/mbt" && location.pathname != "/") &&<a onClick={ShowHideFolder}>
              <img src="/images/folder.svg" width="25px" height="20" />
            </a>}
            {ShowFolder && (
              <div className="floder-links">
                {AppContext.PermittedAppList &&
                  AppContext.PermittedAppList.map(
                    (Item: any, index: number) => (
                      <React.Fragment key={index}>
                        {AppContext.App === Item.AppID ? (
                          <a
                            className={"c-" + (index + 1)}
                            title={Item.AppDescription}
                          >
                            <img
                              src={Item.Img}
                              width="17px"
                              height="15px"
                              alt={Item.AppName}
                            />
                            <span>{Item.AppName}</span>
                          </a>
                        ) : (
                          <a
                            className={"c-" + (index + 1)}
                            title={Item.AppDescription}
                            href={Item.WebURL}
                          >
                            <img
                              src={Item.Img}
                              width="17px"
                              height="15px"
                              alt={Item.AppName}
                            />
                            <span>{Item.AppName}</span>
                          </a>
                        )}
                        <hr />
                      </React.Fragment>
                    )
                  )}
              </div>
            )}
          </div>
          <a
            onClick={() => {
              getContactUsPageData();
              ShowHideContact();
            }}
          >
            <img src="/images/help.svg" width="24px" height="24" />
          </a>
          {/* <a>
            <img src="/images/envelope.svg" width="32px" height="" />
          </a> */}
          <div className="log-drop" ref={userRef}>
            <a className="log-drop-a" onClick={ShowHideLogoutMenu}>
              <img
                src={globalState.LogInUserImage}
                width="29px"
                height=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "/images/dummyuser.svg";
                }}
              />
              <span>{globalState.LogInUserName}</span>
            </a>
            {showLogout && (
              <div className="logout-menu">
                <div className="log-user-image">
                  <img
                    src={globalState.LogInUserImage}
                    width="29px"
                    height=""
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = "/images/dummyuser.svg";
                    }}
                  />
                </div>
                <div className="setting-log">
                  <a></a>
                  <a href={AppContext.AuthWebLink+"?lgo=1"}>Sign out</a>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
      {ShowContact && (
        <div className="popup-help">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>Contact Support</h3>
                <a className="close-help" onClick={ShowHideContact}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="help-row">
                <p>Name:</p>
                <input
                  value={contactObject.Name}
                  onChange={(e) =>
                    setcontactObject((prevState: any) => ({
                      ...prevState,
                      Name: e.target.value,
                    }))
                  }
                  placeholder="your name"
                  type="text"
                />
              </div>
              <div className="help-row">
                <p>Email:</p>
                <input
                  value={contactObject.Email}
                  onChange={(e) =>
                    setcontactObject((prevState: any) => ({
                      ...prevState,
                      Email: e.target.value,
                    }))
                  }
                  placeholder="your email address"
                  type="text"
                />
              </div>
              {/* <div className="help-row">
              <p>Subject:</p>
              <input
                value={contactObject.Subject}
                onChange={(e) =>
                  setcontactObject((prevState: any) => ({
                    ...prevState,
                    Subject: e.target.value,
                  }))
                }
                placeholder="Subject"
                type="text"
              />
            </div> */}
              <div className="help-row">
                <p>Type:</p>
                <select
                  className="input-field select-field"
                  defaultValue={contactObject.ContactReason}
                  onChange={(e) =>
                    setcontactObject((prevState: any) => ({
                      ...prevState,
                      ContactReason: e.target.value,
                    }))
                  }
                >
                  <option value="">Select</option>
                  {contactUsReason &&
                    contactUsReason.map((Item: any, index) => (
                      <option
                        value={Item.PTSupportTypeID}
                        key={Item.PTSupportTypeID}
                      >
                        {Item.Name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="help-row help-area">
                <p>Message:</p>
                <textarea
                  value={contactObject.Message}
                  onChange={(e) =>
                    setcontactObject((prevState: any) => ({
                      ...prevState,
                      Message: e.target.value,
                    }))
                  }
                  placeholder="type your message..."
                ></textarea>
              </div>
              <div className="help-row help-area flex-start">
                <p>File/Image:</p>
                <div className="drad-drop">
                  <DragDrop
                    ParentFeatures={dragDropFeatures}
                    DragDropCallback={handleDragDropCallback}
                    TriggerChild={triggerChild}
                  />
                </div>
              </div>
              <div className="help-btns">
                <a onClick={() => checkValidation(true)}>Submit</a>
                <a onClick={ShowHideContact}>Cancel</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
