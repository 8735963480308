import { useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import AppContext from "../Context/Context";
import NavigateURL from "../NavigateURL";
import { useGlobalState } from "../Context/GlobalStateContext";
export default function RequireAuth({ children }: any) {
  const location = useLocation();
  let completeURL = location.pathname + location.search;
  const [IsUserLoggedIn, setIsUserLoggedIn] = useState(true);
  const [OkToGo, setOkToGo] = useState(false);

  const globalState = useGlobalState();

  useEffect(() => {
    const CheckIsUserLoggedIn = async () => {
      const Local_Token = process.env.REACT_APP_Local_Token;
      if (
        Local_Token &&
        Local_Token !== "" &&
        !AppContext.IsJwtExpired(Local_Token)
      ) {
        SetUserInfo(1, Local_Token);
        return true;
      } else {
        let ReturnData = await AppContext.CheckLoginValidity();
        if (ReturnData.role === "") {
          return false;
        } else {
          SetUserInfo(2, ReturnData);
          return true;
        }
      }
    };

    const SetUserInfo = (type: number, data: any) => {
      if (type === 1) {
        if (data && data !== "") var UserInfo: any = jwt_decode(data);
        if (UserInfo && UserInfo !== "") {
          globalState.setLogInUserName(UserInfo.username);
          globalState.setLogInUserImage(UserInfo.userimage);
          globalState.setLogInUserRole(UserInfo.role);
        }
      } else {
        globalState.setLogInUserName(data.username);
        globalState.setLogInUserImage(data.userimage);
        globalState.setLogInUserRole(data.role);
      }
    };

    (async () => {
      if ((await CheckIsUserLoggedIn()) === true) {
        let havePermission = await AppContext.CheckAccessAndSettings(
          completeURL,0 
        );
        if (havePermission) {
          setIsUserLoggedIn(true);
        } else {
          setIsUserLoggedIn(false);
        }

        globalState.setManageAdmin(AppContext.ManageAdmin);
      } else {
        setIsUserLoggedIn(false);
      }
      setOkToGo(true);
    })();

    return () => {
      // this now gets called when the component unmounts
    };
  }, []);

  let ReturnData = "" as any;
  if(IsUserLoggedIn === true && OkToGo){
    ReturnData = children;
  }else if(IsUserLoggedIn === false && OkToGo){
    ReturnData = <NavigateURL />;
  }
  return ReturnData; 
}
