import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../Context/GlobalStateContext";
import AppContext from "../Context/Context";
function MBT() {
  const navigate = useNavigate();
  const globalState = useGlobalState();
  useEffect(() => {
    // navigate("/apoactivity");
  }, []);

  return (
    <div className="app-main">
      <div className="apps-inn">
        {AppContext.PermittedAppList &&
          AppContext.PermittedAppList.map((Item: any, index: number) => (
            <React.Fragment key={index}>
              <a
                className={"c-" + (index + 1)}
                title={Item.AppDescription}
                href={Item.WebURL}
              >
                <span className="a-inn">
                  <img
                    src={Item.Img}
                    width="17px"
                    height="15px"
                    alt={Item.AppName}
                  />
                  <span>{Item.AppDescription}</span>
                </span>
              </a>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
}

export default MBT;
