import { createContext } from "react";
import jwt_decode from "jwt-decode";
// import { NotificationManager } from "react-notifications";
import axios from "axios";
import axiosAuth from "../Axios/Interceptors";
import * as Utility from "../Shared/Utility";
import { useGlobalState } from "./GlobalStateContext";
const AppContext = createContext(null) as any;

AppContext.UserGeneralData = [];

AppContext.App = 1;
AppContext.PermittedApps = "";
AppContext.PermittedAppList = [];
AppContext.ManageAdmin = false;


//Live
AppContext.AuthWebLink = "https://auth.jlrmenabusinesstools.com/login" as any;
AppContext.OldAppLink = "https://bp.jlrmenabusinesstools.com";
AppContext.AppBaseURL = "https://apibpdata.jlrmenabusinesstools.com/";
AppContext.AppAuthBaseURL = "https://apiauthdata.jlrmenabusinesstools.com/";
AppContext.CommAPI = "https://apicommdata.jlrmenabusinesstools.com/";
AppContext.FileManegmentAPI = "https://apimanagefilesdata.jlrmenabusinesstools.com/";
AppContext.EmailAPIBaseURL = "https://apiemailsdata.jlrmenabusinesstools.com/";



//Demo
// AppContext.AuthWebLink = "https://authdemo.jlrmenabusinesstools.com/login" as any;
// AppContext.OldAppLink = "https://bpdemo.jlrmenabusinesstools.com";
// AppContext.AppBaseURL = "https://apibpdatademo.jlrmenabusinesstools.com/";
// AppContext.AppAuthBaseURL = "https://apiauthdatademo.jlrmenabusinesstools.com/";
// AppContext.CommAPI = "https://apicommdatademo.jlrmenabusinesstools.com/";
// AppContext.FileManegmentAPI = "https://apimanagefilesdatademo.jlrmenabusinesstools.com/";
// AppContext.EmailAPIBaseURL = "https://apiemailsdatademo.jlrmenabusinesstools.com/";




AppContext.ConvertDateTimeToLocal = (date: any) => {
  date = new Date(date);
  var localOffset = date.getTimezoneOffset() * 60000;
  var localTime = date.getTime();
  date = localTime - localOffset;
  date = new Date(date);

  // let text = date.toLocaleString();
  // date = new Date(text);

  return date;
};

AppContext.IsJwtExpired = (token: any) => {
  if (token && token !== "") {
    var UserInfo: any = jwt_decode(token);
    let currentDate = new Date();
    if (UserInfo.exp * 1000 < currentDate.getTime()) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

AppContext.CheckLoginValidity = async () => {
  let object = {} as any;
  let ReturnData = { role: "" };
  await axiosAuth
    .post(AppContext.AppAuthBaseURL + "api/SiteUser/CheckLoginValidity", object)
    .then((response) => {
      if (response.data.Status === 1) {
        var l_response = response.data.Data[0][0];
        ReturnData = l_response;
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
      return ReturnData;
    });

  return ReturnData;
};

AppContext.CheckAccessAndSettings = async (completeURL: string, jlr: any) => {
  let object = {} as any;
  object.appid = 0;
  object.other = completeURL;
  let permissionStatus = false;
  await axiosAuth
    .post(
      AppContext.AppAuthBaseURL + "api/SiteUser/CheckAccessAndSettings",
      object
    )
    .then((response) => {
      if (response.data.Status === 1) {
        var l_response = response.data.Data[0][0];
        debugger;
        AppContext.PermittedApps = l_response.AppList;
        AppContext.PermittedAppList = response.data.Data[1];
        // AppContext.ManageAdmin = response.data.Data[2][0].ManageAdmineSignEtc;
        let Status = l_response.Status;
        if (Status === 1) {
          if (jlr === 0) {
            permissionStatus = true;
          } else {
            permissionStatus =  true; //response.data.Data[2][0].ManageAdmineSignEtc;
          }
        }
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
      return permissionStatus;
    });

  return permissionStatus;
};

AppContext.CheckAccessAsAdmin = async () => {
  let object = {} as any;
  object.appid = AppContext.App;
  let isAdminLoggedIn = false;
  await axiosAuth
    .post(AppContext.AppAuthBaseURL + "api/SiteUser/CheckAccessAsAdmin", object)
    .then((response) => {
      if (response.data.Status === 1) {
        var l_response = response.data;
        let Status = l_response.Status;
        if (Status === 1) {
          isAdminLoggedIn = true;
        }
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
      return isAdminLoggedIn;
    });

  return isAdminLoggedIn;
};

AppContext.GetBusinessPlanGeneralData = () => {
  let object = {} as any;
  object.bpid = AppContext.BPID;
  axiosAuth
    .post(
      AppContext.AppBaseURL + "api/PFIBusinessPlan/GetBusinessPlanGeneralData",
      object
    )
    .then((response) => {
      if (response.data.Status === 1) {
        var l_response = response.data.Data[0][0];
        AppContext.BusinessPlanGeneralData = l_response;
        Utility.SetBPGeneralData();
      }
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};


AppContext.PTCheckPasswordValidation = (FieldValue: any) => {
  //var RegExpresson = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  var RegExpresson = /^(?=.*["}{)(|,'?><_~!@#\$%\^&\*^%:;.`/-])/;
  //var l_boolValid = RegExpresson.test(FieldValue);
  var l_boolValid = true;
  var HasWhiteSpace = false;
  var Message = "";
  if (/\s/.test(FieldValue)) {
    // It has any kind of whitespace
    HasWhiteSpace = true;
  }
  if (HasWhiteSpace) {
    l_boolValid = false;
    IsAnyError = true;

    Message =
      "<br /><span class='p-error'>White spaces are not allowed.</span>";
  }
  var IsAnyError = false;
  if (FieldValue.length < 8) {
    Message +=
      "<br /><span class='p-error'>A minimum of 8 characters required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>A minimum of 8 characters required.</span>";
  }

  if (!/[a-z]/.test(FieldValue)) {
    Message +=
      "<br /><span class='p-error'>At least one lowercase letter is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>At least one lowercase letter is required.</span>";
  }

  if (FieldValue == FieldValue.toLowerCase()) {
    Message +=
      "<br /><span class='p-error'>At least one capital letter is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>At least one capital letter is required.</span>";
  }

  if (!/\d/.test(FieldValue)) {
    Message +=
      "<br /><span class='p-error'>At least one number is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<br /><span class='p-ok'>At least one number is required.</span>";
  }
  // if (/^[a-zA-Z0-9- ]*$/.test(FieldValue) == true) {
  if (!RegExpresson.test(FieldValue)) {
    Message +=
      "<br /><span class='p-error'>At least one special character (e.g. @) is required.</span>";
    IsAnyError = true;
    l_boolValid = false;
  } else {
    Message +=
      "<span class='p-ok'>At least one special character (e.g. @) is required.</span>";
  }
  // }

  return { Valid: l_boolValid, Message: Message };
};

AppContext.GenerateUniqueGUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

AppContext.GetFileIconToDisplay = (FilePath: any) => {
  var IconURL = FilePath;
  var parts = FilePath.split(".");
  var ext = parts[parts.length - 1];
  switch (ext.toLowerCase()) {
    case "pdf":
      IconURL = "/DragDropIcons/pdficon.jpg";
      break;
    case "docx":
      IconURL = "/DragDropIcons/Word.jpg";
      break;
    case "doc":
      IconURL = "/DragDropIcons/Word.jpg";
      break;
    case "xlsx":
      IconURL = "/DragDropIcons/xls_icon.png";
      break;
    case "xls":
      IconURL = "/DragDropIcons/xls_icon.png";
      break;
    case "pptx":
      IconURL = "/DragDropIcons/ppt.png";
      break;
    case "ppt":
      IconURL = "/DragDropIcons/ppt.png";
      break;
    case "wav":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "mid":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "midi":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "wma":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "mp3":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "ogg":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "rma":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "au":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "ea":
      IconURL = "/DragDropIcons/audio.png";
      break;
    case "avi":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "mp4":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "divx":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "wmv":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "mkv":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "flv":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "avi":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "vob":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "ogv":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "wmv":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "m4v":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "mov":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "wmv":
      IconURL = "/DragDropIcons/video.png";
      break;
    case "zip":
      IconURL = "/DragDropIcons/zip.png";
      break;
    case "rar":
      IconURL = "/DragDropIcons/zip.png";
      break;
    default:
      IconURL = FilePath;
  }
  return IconURL;
};

AppContext.SortJsonData = (JsonData: any, ColumnName: any) => {
  let SortedData = JsonData.sort((a: any, b: any) => {
    if (a[ColumnName] < b[ColumnName]) {
      return -1;
    }
  });

  return SortedData;
};

export default AppContext;
