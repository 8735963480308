import React from "react";
import { useState, useEffect, useRef } from "react";
type Props = {
  NotificationObject: {
    ShowPopup: boolean;
    Heading: string;
    Message: string;
  };
  NotificationCallBack: any;
};
function Notification(props: Props) {
  const [ShowPopup, setShowPopup] = useState(
    props.NotificationObject.ShowPopup
  );
  function ClosePopUp() {
    props.NotificationCallBack({ show: false });
  }
  return (
    <div>
      {props.NotificationObject.ShowPopup && (
        <div className="popup-help popup-help-index">
          <div className="help-popup-display">
            <div className="help-inner">
              <div className="help-head">
                <h3>{props.NotificationObject.Heading}</h3>
                <a className="close-help" onClick={() => ClosePopUp()}>
                  <img
                    src="/images/close.svg"
                    width="15px"
                    height="15px"
                    alt="cross"
                  />
                </a>
              </div>
              <div className="popup-message">
                <span
                  dangerouslySetInnerHTML={{
                    __html: props.NotificationObject.Message,
                  }}
                ></span>
              </div>
              <div className="help-btns">
                <a onClick={() => ClosePopUp()}>Close</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Notification;
